export default {
    tableFields:   [
      
        {
            name:      'element_a.name',
            title:      'Element A',
            sortField: 'element_a.name',
         
        },
        {
            name:      'id_value_a',
            title:      'ID A',
            sortField: 'element_a.id',
           
        },
        {
            name:      'action.name',
            title:      'Action',
            sortField: 'element_a.name',
          
        },
        {
            name:      'element_b.name',
            title:      'Element B',
            sortField: 'element_b.name',
           
        },
        {
            name:      'element_b.id',
            title:      'ID B',
            sortField: 'element_b.id',
           
        },
        {
            name:      'value_action',
            title:      'Value',
            sortField: 'value_action',
           
        },
       
    ],
    sortFunctions: {
        'name': function (item1, item2) {
            return item1 >= item2 ? 1 : -1
        },
    }
}
